import React, { useEffect, useState } from "react";
import withUser from "../../hocs/with_user";
import { MemberPointActivity } from "../state";
import { getMemberActivity } from "../../api/member_api";
import DateRange from "../../components/date_range";
import LoadingSpinner from "../../components/loading_spinner";
import { format, subDays, addDays } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Select, Alert } from "antd";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import defaultPhoto from "../../assets/image/user.png";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";

const { Option } = Select;
type S =
  | { status: "loading" }
  | { status: "loaded"; data: MemberPointActivity[] }
  | { status: "error"; error: string };
const PointExpireList = (props: any) => {
  const merchantId = props.user?.userInfo?.merchantId || null;
  const [state, setState] = useState({ status: "loading" } as S);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  useEffect(() => {
    if (merchantId) {
      getMemberActivity({
        merchantId,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }).then((res) => {
        if (res.success) {
          setState({
            status: "loaded",
            data: (res.data || []).filter((mp) => mp.earnPoint > 0),
          });
        }
      });
    }
  }, [merchantId, ranges]);
  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      align: "center",
      render: (v: number, record: MemberPointActivity) => (
        <Link to={paths.getMemberDetailRoute(record.memberId)}>
          <Avatar
            src={record.memberPhoto || defaultPhoto}
            size={45}
            onClick={() => { }}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberName || "".localeCompare(b.memberName || ""),
      render: (v: number, record: MemberPointActivity) => {
        const val = record.memberName || "";
        return val;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberPhone || "".localeCompare(b.memberPhone || ""),
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberPhone || "";
        return val;
      },
    },
    {
      title: "Total Point",
      dataIndex: "totalPoint",
      key: "totalPoint",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberTotalPoint - b.memberTotalPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberTotalPoint || 0;
        return val;
      },
    },
    {
      title: "Point In Period",
      dataIndex: "pointInPeriod",
      key: "pointInPeriod",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.totalPoint - b.totalPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.totalPoint || 0;
        return val;
      },
    },
    {
      title: "Engagement",
      dataIndex: "engagement",
      key: "engagement",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.earnEngagement - b.earnEngagement,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.earnEngagement || 0;
        return val;
      },
    },
    {
      title: "Current Point",
      dataIndex: "currentPoint",
      key: "currentPoint",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberPoint - b.memberPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberPoint || 0;
        return val;
      },
    },
    {
      title: "First Date",
      dataIndex: "firstDate",
      key: "firstDate",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        Number(a.firstActiveAt) - Number(b.firstActiveAt),
      render: (v: number, r: MemberPointActivity) => {
        return format(new Date(Number(r.firstActiveAt)), "yyyy-MMM-dd");
      },
    },
    {
      title: "Last Date",
      dataIndex: "lastDate",
      key: "lastDate",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        Number(a.lastActiveAt) - Number(b.lastActiveAt),
      render: (v: number, r: MemberPointActivity) => {
        return format(new Date(Number(r.lastActiveAt)), "yyyy-MMM-dd");
      },
    },
  ];
  const onExport = () => {
    if (state.status !== "loaded") return;
    setExportLoading(true);
    const fileName =
      "topMember_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    state.data.forEach((p) => {
      const memberName = p.memberName || "";
      const memberPhone = p.memberPhone || "";
      const memberEmail = p.memberEmail || "";
      const currentPoint = p.memberPoint || 0;
      const totalPoint = p.memberTotalPoint || 0;
      const pointInPeriod = p.totalPoint || 0;
      const spendEngagement = p.spendEngagement;
      const earnEngagement = p.earnEngagement;
      const firstActiveDate = format(new Date(Number(p.firstActiveAt)), "yyyy-MMM-dd");
      const lastActiveDate = format(new Date(Number(p.lastActiveAt)), "yyyy-MMM-dd");
      excelData.push({
        memberName,
        memberPhone,
        memberEmail,
        currentPoint,
        totalPoint,
        pointInPeriod,
        spendEngagement,
        earnEngagement,
        lastActiveDate,
        firstActiveDate,
      });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const onSelect = async (startDate: Date, endDate: Date) => {
    setState({ status: "loading" });
    setRanges({ startDate, endDate });
  };
  return (
    <React.Fragment>
      {state.status === "loading" && <LoadingSpinner />}
      {state.status === "loaded" && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Row span={12}>
                  {/* <Typography.Text strong> Top Member </Typography.Text> */}
                  <Alert
                    message="Top member in your membership program"
                    type="info"
                    showIcon
                  />
                </Row>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <DateRange
                  startDate={ranges.startDate}
                  endDate={ranges.endDate}
                  onSelect={onSelect}
                />
                <ExportButton
                  loading={exportLoading || state.status !== "loaded"}
                  onClick={onExport}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Table
              columns={columns}
              dataSource={(state.status === "loaded" ? state.data : []).map(
                (row, i) => {
                  return { ...row, key: i + 1 };
                }
              )}
              pagination={{
                position: ["bottomRight"],
              }}
            />
          </Row>
        </>
      )}
      {state.status === "error" && <span>{state.error}</span>}
    </React.Fragment>
  );
};
export default withUser(PointExpireList) as any;
